
import { Options, Vue } from 'vue-class-component';
import Multiply from '@/components/Multiply.vue'; // @ is an alias to /src

@Options({
  components: {
    Multiply,
  },
})
export default class Home extends Vue {}
