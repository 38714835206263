
import { Options } from 'vue-class-component';
import Base from '@/Base';
import Multiply from '@/components/Multiply.vue';
import Axios from 'axios';
import { DateHelper } from '@/utils/DateHelper';

@Options({
    components: {
        Multiply
    },
})
export default class App extends Base {
    private images: Image[] = [];
    private backgroundImage = {};
    private hiddenWidgets:string[] = [];
    private showFeedback = false;

    public created() {
        this.getImageData();
    }

    private getImageData() {
        Axios.get('/bg/data.json').then((res) => {
            let file = res.data; 
            this.images = file.images as Image[];
            this.setImage();
        });
    }

    private setImage() {
        let bgimage = localStorage.getItem('school_bgimage');
        const today = new Date().toISOString().split('T')[0];
        if (bgimage === null) {
            const ix = Math.floor(Math.random() * this.images.length);
            bgimage = JSON.stringify({ ix: ix, date: today });
            localStorage.setItem('school_bgimage', bgimage);
        }
        const bgInfo = JSON.parse(bgimage);
        if (!DateHelper.isSameDay(new Date(Date.parse(bgInfo.date)), new Date())) {
            bgInfo.ix++;
            if (bgInfo.ix === this.images.length) {
                bgInfo.ix = 0;
            }
            bgInfo.date = today;
            localStorage.setItem('school_bgimage', JSON.stringify(bgInfo));
        }
        this.backgroundImage = this.images[bgInfo.ix];
    }
}

class Image {
    public Artist = '';
    public Contact = '';
    public Imageid = '';
}
