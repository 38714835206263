
import Base from '@/Base';
import { Watch } from 'vue-property-decorator';

enum Status { Start = 1, Multiplying, Done }

export default class Multiply extends Base {
    private minutesGoal = 10;
    private resultGoal = 140;
    private timeLeft = this.minutesGoal * 60; 
    private curStatus = Status.Start;
    private left = 1;
    private right = 1;
    private answer?:number = undefined;
    private correctCount = 0;
    private errorList: string[] = [];
    private falseCount = 0;
    private interval = 0;
    private enters = 0;

    private get remaining(): number {
        return this.resultGoal - this.correctCount;
    }

    private get currentQuestion(): string {
        return `${this.left} x ${this.right} = `;
    }

    private get minutes(): number {
        return Math.floor(this.timeLeft/60);
    }

    private get percentageTime(): number {
        return 100 - (this.timeLeft / (this.minutesGoal * 60)) * 100;
    }

    private get percentageCorrect(): number {
        return Math.floor((this.correctCount / this.resultGoal) * 100);
    }

    private get seconds(): string {
        let res = (this.timeLeft % 60).toString();
        if (res.length === 1) {
            res = '0' + res;
        }
        return res;
    }

    @Watch('minutesGoal', { immediate: true })
    public async onMinutesChanged(newVal: number, oldVal: number) {
        if (oldVal === undefined) return;
        localStorage.setItem('minutes', newVal.toString());
    }

    
    @Watch('resultGoal', { immediate: true })
    public async onResultGoalChanged(newVal: number, oldVal: number) {
        if (oldVal === undefined) return;
        localStorage.setItem('resultgoal', newVal.toString());
    }

    public mounted() {
        const minutes = localStorage.getItem('minutes');
        if (minutes) {
            this.minutesGoal = parseInt(minutes);
        }
        const resultGoal = localStorage.getItem('resultgoal');
        if (resultGoal) {
            this.resultGoal = parseInt(resultGoal);
        }
    }

    private checkMinutes() {
        if(this.minutesGoal < 0.5) {
            this.minutesGoal = 0.5;
        }
    }

    private checkAnswer() {
        if (this.answer === undefined) {
            return;
        }
        if (this.left * this.right === this.answer) {
            this.correctCount++;
        } else {
            this.falseCount++;
            this.errorList.push(`${this.left} x ${this.right} = ${this.answer}`);
        }
        this.enters++;
        this.createQuestion();
    }

    private createQuestion() {
        this.left = Math.floor(Math.random() * 10) + 1;
        this.right = Math.floor(Math.random() * 10) + 1;
        this.answer = undefined;
    }

    private isInStatus(status: Status): boolean {
        return this.curStatus === status;
    }

    private start() {
        this.curStatus = Status.Multiplying;
        this.timeLeft = this.minutesGoal * 60;
        this.createQuestion();
        this.correctCount = 0;
        this.falseCount = 0;
        this.enters = 0;
        this.errorList = [];
        this.interval = setInterval(() => {
            this.timeLeft--;
            if(this.timeLeft <= 0 || this.correctCount >= this.resultGoal) {
                this.end();
            }
        }, 1000);
    }

    private end() {
        this.curStatus = Status.Done;
        clearInterval(this.interval);
    }

    private finish() {
        clearInterval(this.interval);
        this.curStatus = Status.Start;
    }
}

