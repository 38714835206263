import { Vue } from "vue-class-component";

class Translation {
    public Key = '';
    public English = '';
    public Dutch = '';
} 

export default class Base extends Vue {
    private translations: Translation[] = [
        { Key: 'add', English: 'add', Dutch: 'toevoegen' },
        { Key: 'confirm_delete_all_words', English: 'Delete existing words?', Dutch: 'Bestaande woorden verwijderen?' },
        { Key: 'delete_all', English: 'delete all', Dutch: 'alles verwijderen' },
        { Key: 'done', English: 'done', Dutch: 'klaar' },
        { Key: 'difficult', English: 'difficult', Dutch: 'moeilijk' },
        { Key: 'edit_words', English: 'edit words', Dutch: 'woorden edit' },
        { Key: 'edit_words_description', English: 'Add a word (left) and its translation (right). Words will be studied in two directions. Words can be removed below.', Dutch: 'Voeg een woord (links) en de vertaling (rechts) toe. Woorden worden twee kanten op geleerd. In het overzicht kunnen woorden worden verwijderd.' },
        { Key: 'fail', English: 'Awww, keep practicing, you\'ll get there!', Dutch: 'Helaas, blijf goed oefenen!'},
        {
            Key: 'feedback_message', English: 'Do you have fun ideas for other exercies? Did I make a mistake? Want to say something else? I\'d be happy to receive an email from you!',
            Dutch: 'Heb je leuke ideeën voor andere schoolwerkjes? Heb ik een foutje gemaakt? Wil je iets anders zeggen? Ik vind het leuk om mail te krijgen!'
        },
        { Key: 'good', English: 'good', Dutch: 'goed'},
        { Key: 'login', English: 'good', Dutch: 'goed'},
        { Key: 'login_description', English: 'Enter your unique login name (or create a new one).', Dutch: 'Vul je eigen unieke loginnaam in (of maak een nieuwe aan).'},
        { Key: 'next_review', English: 'Next review after', Dutch: 'Volgende oefening na'},
        { Key: 'minutes', English: 'minutes', Dutch: 'minuten'},
        { Key: 'mistakes', English: 'mistakes', Dutch: 'fouten' },
        { Key: 'show_answer', English: 'show answer', Dutch: 'antwoord tonen'},
        { Key: 'start', English: 'start', Dutch: 'start'},
        { Key: 'stop', English: 'stop', Dutch: 'stop'},
        { Key: 'success', English: 'Success!', Dutch: 'Gehaald!'},
        { Key: 'target', English: 'target', Dutch: 'doel'},
        { Key: 'times_tables', English: 'multiplication tables', Dutch: 'tafels'},
        { Key: 'to_target', English: 'to target', Dutch: 'nog'},
        { Key: 'translation', English: 'translation', Dutch: 'vertaling'},
        { Key: 'word', English: 'word', Dutch: 'woord'},
        { Key: 'words', English: 'words', Dutch: 'woordjes'},
        { Key: 'words_done_for_now', English: 'No words left to learn at this moment.', Dutch: 'Op dit moment heb je geen woordjes om te leren.'},
        { Key: 'wrong', English: 'wrong', Dutch: 'fout'},
    ];

    public T(id: string): string {
        const language = 'nl-nl'; // window.navigator.language;
        return this.getTranslation(id, language);
    }

    private getTranslation(id: string, language: string) {
        const translation = this.translations.find(t => t.Key.toLowerCase() === id.toLowerCase());
        if (!translation) {
            return `T('${id}')`;
        }
        switch(language.toLowerCase()) {
            case 'nl-nl':
                return translation.Dutch;
            default:
                return translation.English;
        }
    }
}